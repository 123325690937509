<template>
    <SfInput v-focus class="sf-search-bar" type="search" name="search" :value="value" :placeholder="placeholder"
        :icon="icon" v-bind="$attrs" v-on="listeners" @keyup.enter="$emit('enter', value)" ref="inputRef">
        <template #icon>
            <slot name="icon" v-bind="{ icon }" />
        </template>
    </SfInput>
</template>
<script>
import { onMounted, ref } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { focus } from '~/components/JetUi/utils/directives';

export default {
    name: "JetSearchBar",
    components: { SfInput },
    directives: {
        focus,
    },
    inheritAttrs: false,
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: [Number, String],
            default: null,
        },
        icon: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const inputRef = ref(null);

        onMounted(() => {
            if (inputRef.value) {
                const input = inputRef.value.$el?.querySelector("input");
                if (input) {
                    input.setAttribute("autocomplete", "off");
                }
            }
        })

        return {
            inputRef
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                "keyup.esc": () => this.$emit("input", ""),
                blur: () => this.$emit("blur"),
                "click:icon": () => this.$emit("click:icon", this.value),
            };
        },
    },
};
</script>